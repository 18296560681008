import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91slug_93XuuG7fNY1HMeta } from "/app/pages/post/[slug].vue?macro=true";
import { default as not_45availablehEG4AO3zt9Meta } from "/app/pages/post/not-available.vue?macro=true";
import { default as _91tagName_93jOdCb5ZaoMMeta } from "/app/pages/tag/[tagName].vue?macro=true";
export default [
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___es",
    path: indexN6pT4Un8hYMeta?.path ?? "/es",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XuuG7fNY1HMeta?.name ?? "post-slug___en",
    path: _91slug_93XuuG7fNY1HMeta?.path ?? "/post/:slug()",
    meta: _91slug_93XuuG7fNY1HMeta || {},
    alias: _91slug_93XuuG7fNY1HMeta?.alias || [],
    redirect: _91slug_93XuuG7fNY1HMeta?.redirect || undefined,
    component: () => import("/app/pages/post/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XuuG7fNY1HMeta?.name ?? "post-slug___es",
    path: _91slug_93XuuG7fNY1HMeta?.path ?? "/es/post/:slug()",
    meta: _91slug_93XuuG7fNY1HMeta || {},
    alias: _91slug_93XuuG7fNY1HMeta?.alias || [],
    redirect: _91slug_93XuuG7fNY1HMeta?.redirect || undefined,
    component: () => import("/app/pages/post/[slug].vue").then(m => m.default || m)
  },
  {
    name: not_45availablehEG4AO3zt9Meta?.name ?? "post-not-available___en",
    path: not_45availablehEG4AO3zt9Meta?.path ?? "/post/not-available",
    meta: not_45availablehEG4AO3zt9Meta || {},
    alias: not_45availablehEG4AO3zt9Meta?.alias || [],
    redirect: not_45availablehEG4AO3zt9Meta?.redirect || undefined,
    component: () => import("/app/pages/post/not-available.vue").then(m => m.default || m)
  },
  {
    name: not_45availablehEG4AO3zt9Meta?.name ?? "post-not-available___es",
    path: not_45availablehEG4AO3zt9Meta?.path ?? "/es/post/not-available",
    meta: not_45availablehEG4AO3zt9Meta || {},
    alias: not_45availablehEG4AO3zt9Meta?.alias || [],
    redirect: not_45availablehEG4AO3zt9Meta?.redirect || undefined,
    component: () => import("/app/pages/post/not-available.vue").then(m => m.default || m)
  },
  {
    name: _91tagName_93jOdCb5ZaoMMeta?.name ?? "tag-tagName___en",
    path: _91tagName_93jOdCb5ZaoMMeta?.path ?? "/tag/:tagName()",
    meta: _91tagName_93jOdCb5ZaoMMeta || {},
    alias: _91tagName_93jOdCb5ZaoMMeta?.alias || [],
    redirect: _91tagName_93jOdCb5ZaoMMeta?.redirect || undefined,
    component: () => import("/app/pages/tag/[tagName].vue").then(m => m.default || m)
  },
  {
    name: _91tagName_93jOdCb5ZaoMMeta?.name ?? "tag-tagName___es",
    path: _91tagName_93jOdCb5ZaoMMeta?.path ?? "/es/tag/:tagName()",
    meta: _91tagName_93jOdCb5ZaoMMeta || {},
    alias: _91tagName_93jOdCb5ZaoMMeta?.alias || [],
    redirect: _91tagName_93jOdCb5ZaoMMeta?.redirect || undefined,
    component: () => import("/app/pages/tag/[tagName].vue").then(m => m.default || m)
  }
]